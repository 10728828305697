import React from 'react'
import createBrowserHistory from './utils/createBrowserHistory'
import HistoryContext from '../../context/HistoryContext'
import { ChannelInterface } from '../../interfaces/ChannelInterface'
import HistoryInterface from '../../interfaces/HistoryInterface'
import * as appApi from '../../services/app/app.api'
import Channel from '../Channel'
import ChannelSwitcher from '../ChannelSwitcher'

type Props = {
  channels: ChannelInterface[]
}

const ChannelProvider = (props: Props) => {
  const [history, setHistory] = React.useState<HistoryInterface>()

  const checkRedirect = async () => {
    try {
      const redirect = await appApi.readRedirectFrom(window.location.pathname, window.location.hostname)
      if (redirect.data.to && history) {
        history.push(redirect.data.to)
      }
    } catch (error: any) {
      console.debug(error)
    }
  }

  React.useEffect(() => {
    checkRedirect()
  }, [window.location.pathname])

  React.useEffect(() => {
    setHistory(
      createBrowserHistory(window, {
        basename: '',
        channels: props.channels,
        currentUrl: '/',
        useGlobalHistory: true,
      }),
    )
  }, [])

  if (!history) return null

  return (
    <HistoryContext.Provider value={history}>
      <ChannelSwitcher history={history} channels={props.channels}>
        {props.channels.map(channel => {
          if (!channel.active) return false
          return <Channel key={channel.code} {...channel} />
        })}
      </ChannelSwitcher>
    </HistoryContext.Provider>
  )
}

export default ChannelProvider
