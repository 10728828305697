import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import ChannelContext from '../../context/ChannelContext'
import ChannelInterface, { THEME_NAME } from '../../interfaces/ChannelInterface'

const useFacebook = () => {
  const fbq = window.fbq || _.noop

  const getCurrentPixelId = (channel: ChannelInterface): string | undefined => {
    const paramName = channel.theme === THEME_NAME.FUNKITA ? 'funkitaMetaPixel' : 'funkytrunksMetaPixel'
    return channel.params.find(i => i.name == paramName)?.value || ''
  }
  //DEPRECATED
  const pixelInit = (channel: ChannelInterface) => {
    console.log('pixel init', getCurrentPixelId(channel), channel)
    fbq('init', getCurrentPixelId(channel))
    fbq('track', 'PageView')
  }

  return { getCurrentPixelId, pixelInit }
}

export default useFacebook
