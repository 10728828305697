const emailMaxChars = 120
const passwordMinChars = 5
const firstNameMaxChars = 45
const lastNameMaxChars = 45
const streetMaxChars = 45
const cityMaxChars = 45
const stateMaxChars = 45
const postCodeMaxChars = 8
const phoneMinChars = 7
const phoneMaxChars = 22
const nameMinChars = 2
const firstNameMinChars = 1
const lastNameMinChars = 1
const businessNameMinChars = 1
const businessNameMaxChars = 200

export {
  emailMaxChars,
  passwordMinChars,
  firstNameMaxChars,
  lastNameMaxChars,
  streetMaxChars,
  cityMaxChars,
  stateMaxChars,
  postCodeMaxChars,
  phoneMinChars,
  phoneMaxChars,
  nameMinChars,
  firstNameMinChars,
  lastNameMinChars,
  businessNameMinChars,
  businessNameMaxChars,
}
