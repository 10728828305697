import ls from '@livesession/sdk'
import _ from 'lodash'
import React, { useContext } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styles from './App.module.scss'
import '../../app/styles/index.module.scss'
import HistoryContext from '../../context/HistoryContext'
import { useAuth } from '../../hooks/auth'
import { SubscriberInterface } from '../../hooks/events/events.types'
import useFacebook from '../../hooks/fb/fb'
import * as addressApi from '../../services/address/address.api'
import { readAppStateRequest } from '../../services/app/app.actions'
import * as cartActions from '../../services/cart/cart.actions'
import { readAddressFormatsSuccess } from '../../services/locale/locale.actions'
import { State } from '../../services/reducers'
import GA4Tracker from '../../services/track/track.ga4'
import MetaTracker from '../../services/track/track.meta'
import { getThemeFromUrl } from '../../utils/channel'
import { DEFAULT_COUNTRY_CODE, getPreferredLocale } from '../../utils/locale'
import { loadLocaleData } from '../../utils/localeData'
import { _at } from '../../utils/translations'
import AppLayout from '../AppLayout'
import AppProvider from '../AppProvider/AppProvider'
import { THEME_FUNKITA } from '../Channel/loadTheme'
import LoadinSpinner from '../LoadingSpinner'

type Props = {
  translations: Record<string, string>
  redirect?: string | false
}

const App = (props: Props) => {
  const _mounted = React.useRef<boolean>(false)
  const dispatch = useDispatch()
  const [translations, setTranslations] = React.useState<any>(props.translations)
  const [subscribers, setSubscribers] = React.useState<SubscriberInterface[]>([])
  const loadedAppState = useSelector((store: State) => store.locale?.currency?.isoCode)
  const language = useSelector((store: State) => store.locale?.language)
  const channels = useSelector((store: State) => store.global.channels)
  const countryByIp = useSelector((store: State) => store.global.countryByIp) || DEFAULT_COUNTRY_CODE
  const user = useSelector((store: State) => store.user)
  const theme = getThemeFromUrl()
  const [lsLoaded, setLsLoaded] = React.useState<boolean>(false)
  const { isAuth, isAuthSoft, logOut } = useAuth()
  const facebook = useFacebook()
  //window.APP_STATE = appState
  const fetchAddressFormats = async () => {
    try {
      const response = await addressApi.readAddressFormats()
      dispatch(readAddressFormatsSuccess(response.data))
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    _mounted.current = true

    ls.init(process.env.REACT_APP_LS_ID || 'NO TRACK', {
      keystrokes: true,
    })

    if (process.env.REACT_APP_LS_DEV === 'true') {
      ls.debug()
    }

    setLsLoaded(true)
    fetchAddressFormats()
    dispatch(readAppStateRequest(getPreferredLocale()))

    return () => {
      _mounted.current = false
    }
  }, [])

  React.useEffect(() => {
    const loadMomentLocales = async () => {
      await import('moment/locale/en-au')
      await import('moment/locale/en-in')
      await import('moment/locale/pl')
    }

    loadMomentLocales()

    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        dispatch(cartActions.cartReadRequest())
        if (user.profile?.id && isAuthSoft()) {
          isAuth(_.noop, () => {
            logOut()
          })
        }
      }
    }
    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])

  React.useEffect(() => {
    const loadTranslation = async () => {
      const data = await loadLocaleData(language.isoCode)
      if (_mounted.current) {
        setTranslations(data)
      }
    }
    loadTranslation()
  }, [language.isoCode])

  // React.useEffect(() => {
  //   if (loadedAppState) {
  //     const trackers = [GA4Tracker(countryByIp, process.env.REACT_APP_GTM_ID)]
  //     setSubscribers(trackers)
  //     trackers.forEach(s => s.init && s.init())
  //     return () => {
  //       trackers.forEach(s => s.deinit && s.deinit())
  //     }
  //   }
  // }, [loadedAppState])

  React.useEffect(() => {
    if (loadedAppState && channels?.current) {
      // facebook.pixelInit(channels.current)
      const metaPixel = facebook.getCurrentPixelId(channels.current)

      const globalMetaFKTracker = MetaTracker(process.env.REACT_APP_FBPIXEL_FUNKITA)
      const globalMetaFTTracker = MetaTracker(process.env.REACT_APP_FBPIXEL_FUNKYTRUNKS)
      const globalPixelTracker = channels.current.theme === THEME_FUNKITA ? globalMetaFKTracker : globalMetaFTTracker

      const trackers = [GA4Tracker(countryByIp, process.env.REACT_APP_GTM_ID), MetaTracker(metaPixel), globalPixelTracker]
      setSubscribers(trackers)
      trackers.forEach(s => s.init && s.init())
      return () => {
        trackers.forEach(s => s.deinit && s.deinit())
      }
    }
  }, [channels?.current.id, loadedAppState])

  if (!loadedAppState || !lsLoaded || !channels) return <LoadinSpinner classes={{ spinner: styles[`app__loader--${theme}`] }} />

  return (
    <IntlProvider locale={language.isoCode} messages={translations} onError={() => {}}>
      <AppProvider redirect={props.redirect} subscribers={subscribers}>
        <AppLayout channels={[channels.current, channels.alternate]} />
      </AppProvider>
    </IntlProvider>
  )
}

export default App
